<template>
  <div class="bg-white" v-if="loaded">
    <!-- Background color split screen for large screens -->
    <div class="hidden lg:block fixed top-0 left-0 w-7/12 h-full bg-white" aria-hidden="true" />
    <div class="hidden lg:block fixed top-0 right-0 w-5/12 h-full bg-gray-100 md:border-l" aria-hidden="true" />

    <header
      class="relative max-w-8xl mx-auto bg-gray-100 py-6 lg:bg-transparent lg:grid lg:grid-cols-2 lg:gap-x-16 lg:px-8 lg:pt-16 lg:pb-6">
      <div class="max-w-2xl mx-auto px-4 lg:max-w-lg lg:w-full lg:px-0">
        <a href="https://corsiformazione.online/">
          <span class="sr-only">Workflow</span>
          <img src="https://cdn.shopify.com/s/files/1/0514/6502/7769/files/corsiformazione.online_logo.png?2590" alt=""
            class="h-8 w-auto lg:hidden" />
          <img src="https://cdn.shopify.com/s/files/1/0514/6502/7769/files/corsiformazione.online_logo.png?2590" alt=""
            class="hidden lg:block h-8 w-auto" />
        </a>

        <nav v-if="this.$router.currentRoute.value.path.indexOf('/thank-you') === -1" aria-label="Progress"
          class="hidden sm:block text-xs mt-4">
          <ol role="list" class="flex space-x-2">
            <li class="flex items-center">
              <a v-if="this.$router.currentRoute.value.path === '/step1'" aria-current="page"
                class="text-gray-900">Informazioni</a>
              <a v-else class="text-gray-500">Informazioni</a>
            </li>
            <ChevronRightIcon class="w-5 h-5 text-gray-300 ml-2" aria-hidden="true" />
            <li class="flex items-center">
              <a v-if="this.$router.currentRoute.value.path === '/step2'" aria-current="page"
                class="text-gray-900">Pagamento</a>
              <a v-else class="text-gray-500">Pagamento</a>
            </li>
          </ol>
        </nav>

      </div>
    </header>

    <main class="relative grid grid-cols-1 max-w-7xl mx-auto lg:px-6 lg:grid-cols-12">
      <h1 class="sr-only">Checkout</h1>

      <section aria-labelledby="summary-heading"
        class="bg-gray-100 text-gray-600 pt-6 md:pb-12 pb-6  md:px-10 lg:w-full lg:mx-auto lg:px-0 lg:pt-0 lg:pb-24 lg:bg-transparent lg:row-start-1 lg:col-start-9 lg:col-end-13">
        <div class="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
          <h2 id="summary-heading" class="sr-only">Riepilogo</h2>

          <dl>
            <dt class="text-sm font-medium">Riepilogo</dt>
          </dl>

          <ul role="list" class="text-sm font-medium divide-y divide-white divide-opacity-10">
            <li v-for="product in products" :key="product.id" class="flex items-center py-6 space-x-4">
              <img :src="'https://abilitazioni-corsi-core.caprover.shopifive.com/' + product.immagine[0].url"
                :alt="product.immagine[0].name" class="flex-none w-20 h-20 rounded-md object-center object-cover" />
              <div class="flex-auto space-y-1">
                <h3 class="text-gray-600">{{ product.titolo }}</h3>
              </div>
              <p class="flex-none text-base font-medium text-gray-600">{{ product.prezzo }} €</p>
            </li>
          </ul>

          <dl class="text-sm font-medium space-y-6 border-t border-gray-600 border-opacity-10 pt-6">

            <form v-on:submit.prevent v-if="this.$router.currentRoute.value.path.indexOf('/thank-you') === -1">
              <div class="flex space-x-4 mt-1">
                <input type="text" id="discount-code" name="discount-code" v-model="discount"
                  placeholder="Codice sconto"
                  class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                <button v-on:click="checkCoupon()" type="submit"
                  class="bg-yellow-400 bg-opacity-80 text-sm font-medium text-gray-900 rounded-md px-4 hover:bg-yellow-400 hover:bg-opacity-90 focus:outline-none">Applica</button>
              </div>
              <div class="flex max-w-full">
                <span class="mt-2 text-red-600 text-sm" v-if="couponError">Il codice sconto inserito non è valido oppure
                  già inserito</span>
              </div>
              <div class="inline-flex space-x-2 max-w-full flex-wrap">
                <!-- <DiscountTag v-for="discount in products[0].coupons" v-bind:key="discount.id" :codice="discount.titolo" />                                 -->

                <span v-for="discount in products[0].coupons" v-bind:key="discount.id"
                  class="text-gray-900 mt-4 inline-flex items-center bg-gray-200 px-4 py-2 rounded">
                  <div class="h-4 w-4 float-left text-gray-600 mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd"
                        d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd" />
                    </svg>
                  </div>
                  <div class="inline-block">{{ discount.titolo }}</div>
                  <div class="h-4 w-4 float-left text-gray-600 ml-2 cursor-pointer"
                    v-on:click="deleteDiscount(discount.titolo)">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd" />
                    </svg>
                  </div>
                </span>
              </div>
            </form>

            <div class="flex items-center justify-between">
              <dt>Subtotale</dt>
              <dd>{{ cartTotal }} €</dd>
            </div>

            <div v-for="discount in products[0].coupons" v-bind:key="discount.id"
              class="flex items-center justify-between">
              <dt>
                <span class="inline-flex">Sconto</span>
                <span class="inline-flex text-xs ml-4 items-center">
                  <div class="h-3 w-3 float-left text-gray-600 mr-1">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd"
                        d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
                        clipRule="evenodd" />
                    </svg>
                  </div>
                  {{ discount.titolo }}
                </span>
              </dt>
              <dd>-{{ discount.valore }} €</dd>
            </div>

            <div
              class="flex items-center justify-between border-t border-gray-600 border-opacity-10 text-gray-600 pt-6">
              <dt class="text-base">Totale</dt>
              <dd class="text-2xl flex items-end">
                <p class="text-base pr-6">EUR</p>
                {{ cartWithDiscount }} €
              </dd>
            </div>

            <div v-for="offer in products[0].Offerte" :key="offer.id"
              class="border-t border-gray-600 border-opacity-10 pt-6">
              <div class="grid grid-cols-6 gap-2">
                <div class="w-100 col-start-1" v-html="offer.immagine"></div>
                <div class="col-span-5 col-end-7">
                  <h4 class="text-md text-gray-900 mb-2">{{ offer.titolo }}</h4>
                  <p class="text-xs text-gray-500 font-light">{{ offer.testo }}</p>
                </div>
              </div>
            </div>

          </dl>
        </div>
      </section>

      <router-view />

    </main>
  </div>
</template>

<script>
import axios from 'axios';
import { ChevronRightIcon } from '@heroicons/vue/solid'
// import DiscountTag from './DiscountTag.vue';
import { state, setProductId, setProductPagamento, setShopifyId, setProductPrezzo, setProductThankYou } from '../components/Product';
import { computed } from 'vue';
import { setUser } from './User';

const _product = computed({
  get: () => state.id,
  set: value => setProductId(value)
});

const products = [];

export default {
  setup() {
    return {
      products,
    }
  },
  data: () => {
    return {
      loaded: false,
      couponError: false,
      discount: "",
      refreshKey: 0,
    }
  },
  methods: {
    deleteDiscount(discount) {
      this.products[0].coupons = this.products[0].coupons.filter(coupon => coupon.titolo != discount);
      this.refreshKey++;
    },
    checkCoupon() {
      let coupon_exists = this.products[0].coupons.filter(coupon => coupon.titolo == this.discount);
      if (this.discount == "PRIMARATA" && coupon_exists.length <= 0) {
        this.products[0].coupons.push({
          id: Math.random(),
          titolo: "PRIMARATA",
          valore: this.products[0].prezzo == 500 ? 350 : this.products[0].prezzo == 750 ? 600 : 850
        });
        this.couponError = false;
      } else {
        this.couponError = true;
      }
      this.refreshKey++;
    },
    async getProduct() {
      if (_product.value) {
        return
      }
      try {
        if (!this.$route.params.id) {
          window.open('https://corsiformazione.online/', "_self");
        }
        setProductId(this.$route.params.id);
        let product = await axios.get(`https://abilitazioni-corsi-core.caprover.shopifive.com/checkouts/${this.$route.params.id}`);
        this.products = [product.data];
        setProductPagamento(product.data.pagamento);
        setUser('prodotto', product.data.titolo);
        setProductPrezzo(product.data.prezzo);
        setShopifyId(product.data.shopify_id);
        setProductThankYou(product.data.thankyou);
        if (!this.$route.params.order) {
          this.$router.push({ name: 'Step1', params: { id: product.data.id } });
        }
        //AGGIUNGO PACCHETTO DOCENTI OMAGGIO                
        // let pacchettoDocenti = {
        //   id: "",
        //   titolo: "Offerta Pacchetto Corsi LIM TABLET CODING PEKIT EXPERT per personale Docente",
        //   pagamento: "",
        //   shopifyId: 6294510764217,
        //   prezzo: "199",
        //   immagine: [
        //     {
        //       url: "/uploads/048903d255204851a8ccc62094587182.jpeg"
        //     }
        //   ]
        // };
        // this.products.push(pacchettoDocenti);  
        this.loaded = true;
      } catch (error) {
        console.log(error);
      }
    }
  },
  computed: {
    cartTotal: function () {
      return this.products.reduce((acc, item) => {
        return acc + parseFloat(item.prezzo);
      }, 0);
    },
    cartWithDiscount: function () {
      this.refreshKey;
      let cartTotal = this.products.reduce((acc, item) => {
        return acc + parseFloat(item.prezzo);
      }, 0)
      let cartDiscounts = this.products[0].coupons.reduce((acc, item) => {
        return acc + parseFloat(item.valore);
      }, 0);
      setUser("prezzo", parseFloat(cartTotal - cartDiscounts).toFixed(2).toString())
      return parseFloat(cartTotal - cartDiscounts).toFixed(2).toString().replace(".", ",");
    }
  },
  created() {
    this.$watch(
      () => this.$route.params,
      () => {
        this.getProduct()
      },
    )
  },
  components: {
    ChevronRightIcon,
    // DiscountTag
  }
}
</script>