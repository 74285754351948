<template>
  <Checkout msg="Welcome to Your Vue.js App"/>
</template>

<script>
import './index.css'
import Checkout from './components/Checkout.vue'

export default {
  name: 'App',
  components: {
    Checkout
  }
}
</script>