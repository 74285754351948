import {
    createStore
} from '@harlem/core';

const STATE = {
    id: "",
    pagamento: "",
    shopifyId: "",
    prezzo: "",
    thankyou: ""
};

export const {
    state,
    getter,
    mutation,
    ...store
} = createStore('product', STATE);

export const setProductId = mutation('setProductId', (state, payload) => {    
    state.id = payload;
    return state;
});

export const setProductPrezzo = mutation('setProductPrezzo', (state, payload) => {    
    state.prezzo = payload;
    return state;
});

export const setShopifyId = mutation('setShopifyId', (state, payload) => {    
    state.shopifyId = payload;
    return state;
});

export const setProductPagamento = mutation('setProductPagamento', (state, payload) => {    
    state.pagamento = payload;
    return state;
});

export const setProductThankYou = mutation('setProductThankYou', (state, payload) => {    
    state.thankyou = payload;
    return state;
});

