import { createRouter, createWebHashHistory } from 'vue-router'
import Checkout from '../components/Checkout';

const routes = [
  {
    path: '/:id',
    name: 'Checkout',      
    component: Checkout,
  },
  {
    path: '/:id/step1',
    name: 'Step1',
    component: () => import(
      '../components/Step1.vue'
    )
  },
  {
    path: '/:id/step2',
    name: 'Step2',
    component: () => import(
      '../components/Step2.vue'
    )
  },
  {
    path: '/:id/thank-you/:order',
    name: 'thank-you',
    component: () => import(
      '../components/ThankYou.vue'
    )
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
